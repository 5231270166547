* {
  box-sizing: border-box;
  user-select: none;
}

html,
body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.normalText > span {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: normal;
}

.normalText:before {
  width: 20px !important;
  font-size: 14px !important;
  padding-left: 2px;
}

.normalText {
  display: flex !important;
}
